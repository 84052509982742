import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import UtilFunctions from './UtilFunctions';
import './css/homesearch.css';
import questionImg from './images/question.png';
import listImg from './images/list.png';
import greenSpaceship from './images/greenspaceship.png';
import graduationCap from './images/gradudationcap.png';
import graduationCapVert from './images/gradudationcapvert.png';
import { ReactComponent as TelescopeSVG } from './images/telescope.svg';
import { ReactComponent as SeedlingSVG } from './images/seedling.svg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faSquareRootAlt,
  faLaptopCode,
  faPalette,
  faUserFriends,
  faBrain,
  faUserCheck,
  faGlobeAmericas,
  faLandmark,
} from '@fortawesome/free-solid-svg-icons';
import MediaQuery from 'react-responsive';
// import BrowseBySubject from "./components/BrowseBySubject";

// function importAll(r) {
//     let images = {};
//     r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
//     return images;
//   }

//   const images = importAll(require.context('./images/home-search', false, /\.(png|jpe?g|svg)$/));

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: '',
    };
    // this.handleClick = this.handleClick.bind(this)
  }

  handleScrollTo = (eleId) => {
    // Scroll to the target element
    const targetElement = document.getElementById(eleId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="home-search">
          <div className="container-fluid pr-0 pl-0 bg topo-white">
            <div className="container pt-5 pb-5 ps-0 pe-0">
              <p className="bigTitle mb-4 mt-4">Not sure where to start? </p>

              <div className="row mt-4 mb-5 ">
                <div className="col-sm-6 mt-4">
                  <Link
                    to={'/catalog/faq'}
                    className="card  card-horizontal h-100"
                  >
                    <img className="card-img-top" src={questionImg} alt="FAQ" />
                    <div className="card-content-wrapper">
                      <div className="card-header pl-3">
                        <h3 className="card-title ">FAQ and Guide</h3>
                      </div>
                      <div className="card-body pt-0">
                        <p className="card-text">
                          Learn how to search for Classes, review ASU
                          terminology and more!
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 mt-4">
                  <Link
                    to="#"
                    onClick={() => this.handleScrollTo('exploreByGS')}
                    className="card  card-horizontal h-100"
                  >
                    <MediaQuery query="(max-device-width: 575px)">
                      <img
                        className="card-img-top"
                        src={graduationCap}
                        alt="FAQ"
                      />
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 576px)">
                      <img
                        className="card-img-top"
                        src={graduationCapVert}
                        alt="FAQ"
                      />
                    </MediaQuery>
                    <div className="card-content-wrapper">
                      <div className="card-header pl-3">
                        <h3 className="card-title ">
                          General Studies: Gold or Maroon
                        </h3>
                      </div>
                      <div className="card-body pt-0">
                        <p className="card-text">
                          General studies classes fulfill undergraduate
                          graduation requirements. Depending on catalog year,
                          you will follow General Studies Gold or General
                          Studies Maroon.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 mt-4">
                  <Link
                    to={'/catalog/courses?quizOpen=Y'}
                    className="card  card-horizontal h-100"
                  >
                    <img className="card-img-top" src={listImg} alt="FAQ" />
                    <div className="card-content-wrapper">
                      <div className="card-header pl-3">
                        <h3 className="card-title">Class Quiz</h3>
                      </div>
                      <div className="card-body pt-0">
                        <p className="card-text">
                          Take a quiz to discover subjects that interest you.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-6 mt-4">
                  <Link
                    to={'/catalog/classes/classlist/browsebysubject'}
                    className="card  card-horizontal h-100"
                  >
                    <img
                      className="card-img-top"
                      src={greenSpaceship}
                      alt="FAQ"
                    />
                    <div className="card-content-wrapper">
                      <div className="card-header pl-3">
                        <h3 className="card-title">Browse by Subject</h3>
                      </div>
                      <div className="card-body pt-0">
                        <p className="card-text">
                          Discover classes by searching subjects organized by
                          colleges or schools.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid pr-0 pl-0 ">
            <Container className="pt-5 pb-5 mb-5 ps-0 pe-0">
              <p className="bigTitle mb-4 mt-4">Explore classes by Session</p>
              <p className="text">
                ASU offers classes in the fall, spring and summer terms divided
                into multi-week sessions. Each session has different dates for
                important university processes (such as enrolling classes,
                dropping a class, finals, etc.). Consult the{' '}
                <a href="https://students.asu.edu/academic-calendar">
                  Academic Calendar
                </a>{' '}
                for more information.
              </p>
              <div className="row mt-4">
                <div className="col-sm-3">
                  <Link
                    to={getSearchUrl(
                      'session',
                      'A',
                      this.props.term,
                      this.props.path
                    )}
                    className="card"
                  >
                    {/* <img className="card-img-top" src={images['Screen.png']} alt="card"/> */}
                    <div className="card-header">
                      <div className="sectionLabel">Session A</div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-3">
                  <Link
                    to={getSearchUrl(
                      'session',
                      'B',
                      this.props.term,
                      this.props.path
                    )}
                    className="card"
                  >
                    <div className="card-header">
                      <div className="sectionLabel">Session B</div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-3">
                  <Link
                    to={getSearchUrl(
                      'session',
                      'C',
                      this.props.term,
                      this.props.path
                    )}
                    className="card"
                  >
                    <div className="card-header">
                      <div className="sectionLabel">Session C</div>
                    </div>
                  </Link>
                </div>
              </div>

              {/* <p className="sectionLabel mt-4">Location</p>
                        <p className="text">Search for classes at one of our many campuses. Want to take classes 100% online?<br/>Browse thousands of ASU Online classes.</p>
                        <div className="row mt-4">
                            <div className="col-sm-3">
                                <div className="card" onClick={(e) => this.handleClick("location","ASUONLINE")}>
                                    <img className="card-img-top" src={images['Screen.png']} alt="card"/> 
                                    <div className="card-body">
                                    <h5 className="card-title sectionLabel">ASU Online</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="card" onClick={(e) => this.handleClick("location","ICOURSE")}>
                                    <img className="card-img-top" src={images['Screen.png']} alt="card"/> 
                                    <div className="card-body">
                                    <h5 className="card-title sectionLabel">iCourse</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="card" onClick={(e) => this.handleClick("location","TEMPE")}>
                                    <img className="card-img-top" src={images['Screen.png']} alt="card"/> 
                                    <div className="card-body">
                                    <h5 className="card-title sectionLabel">Tempe</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="card" onClick={(e) => this.handleClick("location","WEST")}>
                                    <img className="card-img-top" src={images['Screen.png']} alt="card"/> 
                                    <div className="card-body">
                                    <h5 className="card-title sectionLabel">West</h5>
                                    </div>
                                </div>
                            </div>
                            
                        
                        </div> */}
              {/* <div className="row mt-4">
                            <div className="col-sm-3">
                                <div className="card" onClick={(e) => this.handleClick("location","POLY")}>
                                    <img className="card-img-top" src={images['Screen.png']} alt="card"/> 
                                    <div className="card-body">
                                    <h5 className="card-title sectionLabel">Polytechnic</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="card" onClick={(e) => this.handleClick("location","DTPHX")}>
                                    <img className="card-img-top" src={images['Screen.png']} alt="card"/> 
                                    <div className="card-body">
                                    <h5 className="card-title sectionLabel">Downtown Phoenix</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="card" onClick={(e) => this.handleClick("location","AWC,EAC,CALHC,TUCSON")}>
                                    <img className="card-img-top" src={images['Screen.png']} alt="card"/> 
                                    <div className="card-body">
                                    <h5 className="card-title sectionLabel">Other locations</h5>
                                    </div>
                                </div>
                            </div>
                        
                            
                        
                        </div> */}
            </Container>
          </div>
          <div
            id="exploreByGS"
            className="container-fluid pr-0 pl-0 bg bg-light-gray  pb-5"
            style={{ scrollMarginTop: '3rem' }}
          >
            <div className="container pt-5 pb-5 ps-0 pe-0">
              <p className="bigTitle mb-4 mt-4">
                Explore classes by General Studies
              </p>
              <p className="text">
                General studies classes fulfill undergraduate requirements.
                Visit ASU's{' '}
                <a href="https://catalog.asu.edu/ug_gsr">
                  General Studies requirements
                </a>{' '}
                page to learn more.
              </p>
              <div className="text mt-4">
                <h3>
                  <span className="highlight-gold">General Studies Gold</span>
                </h3>
              </div>
              <p className="text">
                Required for undergraduate students in{' '}
                <b>2024 or later catalog years.</b> Find your catalog year by
                visiting <a href="https://my.asu.edu">My ASU,</a> under My
                Programs.
              </p>

              {/*Start Gold*/}
              <div className="row mt-4">
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-HUAD',
                        this.props.term,
                        this.props.path
                      )}
                      className="card h-100 w-100"
                    >
                      <div className="card-content-wrapper">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faPalette} />
                            </div>
                            <div className="sectionLabel">
                              Humanities, Arts and Design (HUAD)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-SOBE',
                        this.props.term,
                        this.props.path
                      )}
                      className="card h-100 w-100"
                    >
                      <div className="card-content-wrapper">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faBook} />
                            </div>
                            <div className="sectionLabel">
                              Social and Behavioral Sciences (SOBE)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-SCIT',
                        this.props.term,
                        this.props.path
                      )}
                      className="card h-100 w-100"
                    >
                      <div className="card-content-wrapper">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <TelescopeSVG className="svg-inline--fa" />
                            </div>
                            <div className="sectionLabel">
                              Scientific Thinking in Natural Sciences (SCIT)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-QTRS',
                        this.props.term,
                        this.props.path
                      )}
                      className="card h-100 w-100"
                    >
                      <div className="card-content-wrapper">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faBrain} />
                            </div>
                            <div className="sectionLabel">
                              Quantitative Reasoning (QTRS)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row mt-lg-4">
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-MATH',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faSquareRootAlt} />
                            </div>
                            <div className="sectionLabel">
                              Mathematics (MATH)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-AMIT',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faLandmark} />
                            </div>
                            <div className="sectionLabel">
                              American Institutions (AMIT)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-CIVI',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faUserFriends} />
                            </div>
                            <div className="sectionLabel">
                              Governance and Civic Engagement (CIVI)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-GCSI',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faGlobeAmericas} />
                            </div>
                            <div className="sectionLabel">
                              Global Communities, Societies and Individuals
                              (GCSI)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row mt-lg-4">
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-SUST',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <SeedlingSVG className="svg-inline--fa" />
                            </div>
                            <div className="sectionLabel">
                              Sustainability (SUST)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {/*End Gold*/}

              {/*Start Maroon*/}
              <div className="text mt-4">
                <h3>
                  <span className="highlight-maroon">
                    General Studies Maroon
                  </span>
                </h3>
              </div>
              <p className="text">
                Required for undergraduate students in{' '}
                <b>2023 or earlier catalog years.</b> Find your catalog year by
                visiting <a href="https://my.asu.edu">My ASU,</a> under My
                Programs.
              </p>

              <div className="row mt-4">
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-L',
                        this.props.term,
                        this.props.path
                      )}
                      className="card h-100 w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faBook} />
                            </div>
                            <div className="sectionLabel">
                              Literacy and Critical Inquiry (L)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-MA',
                        this.props.term,
                        this.props.path
                      )}
                      className="card h-100 w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faSquareRootAlt} />
                            </div>
                            <div className="sectionLabel">
                              Mathematical Studies (MA)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-CS',
                        this.props.term,
                        this.props.path
                      )}
                      className="card h-100 w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faLaptopCode} />
                            </div>
                            <div className="sectionLabel">
                              Computer/ Statistics/ Quantitative Applications
                              (CS)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-HU',
                        this.props.term,
                        this.props.path
                      )}
                      className="card h-100 w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faPalette} />
                            </div>
                            <div className="sectionLabel">
                              Humanities, Arts and Design (HU)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row mt-lg-4">
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-SB',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faUserFriends} />
                            </div>
                            <div className="sectionLabel">
                              Social-Behavioral Sciences (SB)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-SQ',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <TelescopeSVG className="svg-inline--fa" />
                            </div>
                            <div className="sectionLabel">
                              Natural Science - Quantitative (SQ)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-SG',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faBrain} />
                            </div>
                            <div className="sectionLabel">
                              Natural Science - General (SG)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-C',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faUserCheck} />
                            </div>
                            <div className="sectionLabel">
                              Cultural Diversity in the U.S. (C)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row mt-lg-4">
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-G',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faGlobeAmericas} />
                            </div>
                            <div className="sectionLabel">
                              Global Awareness (G)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-3">
                  <div className="card card-event card-horizontal">
                    <Link
                      to={getSearchUrl(
                        'gs',
                        'GS-H',
                        this.props.term,
                        this.props.path
                      )}
                      className="card w-100"
                    >
                      <div className="card-content-wrapper maroon-card">
                        <div className="card-header">
                          <div className="card-event-icons">
                            <div className="icon">
                              <FontAwesomeIcon icon={faLandmark} />
                            </div>
                            <div className="sectionLabel">
                              Historical Awareness (H)
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {/*End Maroon*/}
            </div>
          </div>
          <div className="container-fluid pr-0 pl-0  pb-5">
            <Container className="pt-5 pb-5 ps-0 pe-0">
              <p className="bigTitle mb-4 mt-4">Explore classes by Level</p>
              <div className="row mt-4 mb-5">
                <div className="col-sm-12 col-md-3">
                  <Link
                    to={getSearchUrl(
                      'level',
                      'undergrad',
                      this.props.term,
                      this.props.path
                    )}
                    className="card"
                  >
                    <div className="card-header">
                      <div className="sectionLabel">Undergraduate (all)</div>
                      <div>100-400 level</div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-12 col-md-3">
                  <Link
                    to={getSearchUrl(
                      'level',
                      '100-199,200-299,lowerdivision',
                      this.props.term,
                      this.props.path
                    )}
                    className="card"
                  >
                    <div className="card-header">
                      <div className="sectionLabel">Lower Division</div>
                      <div>100-200 level</div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-12 col-md-3">
                  <Link
                    to={getSearchUrl(
                      'level',
                      '300-399,400-499,upperdivision',
                      this.props.term,
                      this.props.path
                    )}
                    className="card"
                  >
                    <div className="card-header">
                      <div className="sectionLabel">Upper Division</div>
                      <div>300-400 level</div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-12 col-md-3">
                  <Link
                    to={getSearchUrl(
                      'level',
                      'grad',
                      this.props.term,
                      this.props.path
                    )}
                    className="card"
                  >
                    <div className="card-header">
                      <div className="sectionLabel">Graduate</div>
                      <div>500+ level</div>
                    </div>
                  </Link>
                </div>

                <div className="col-sm-12 col-md-3 mt-md-5">
                  <Link
                    to={getSearchUrl(
                      'honors',
                      '',
                      this.props.term,
                      this.props.path
                    )}
                    className="card"
                  >
                    <div className="card-header">
                      <div className="sectionLabel">Undergraduate</div>
                      <div>Barrett, the Honors College</div>
                    </div>
                  </Link>
                </div>
              </div>

              {/* <p className="sectionLabel mt-5"></p>
                            <div className="row mt-4 mb-5">
                                
                            </div> */}
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;

function getSearchUrl(category, value, term, path) {
  // console.log(term)
  var filters = {
    term: term,
    searchType: 'all',
    campus: [],
    session: [],
    gs: [],
    level: '',
    promod: false,
    honors: false,
  };

  if (category === 'session') {
    filters.session[0] = value;
  } else if (category === 'location') {
    if (value.indexOf(',') > 0) filters.campus = value.split(',');
    else filters.campus[0] = value;
  } else if (category === 'gs') {
    filters.gs[0] = value;
  } else if (category === 'level') {
    filters.level = [value];
  } else if (category === 'honors') {
    filters.honors = true;
  }

  return path + '/classlist?' + UtilFunctions.getQueryStringFromState(filters);
}
